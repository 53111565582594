<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
      >
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">
                Schedule Time: {{ scheduleTime }}
                <a-spin v-if="confirmLoading" />
              </h5>
            </a-col>
            <a-col :span="24" :md="12" class="top-right-header">
              <a-button @click="modalOpen = true">
                Update Schedule Time
              </a-button>
            </a-col>
          </a-row>
        </template>
      </a-card>
    </a-col>
    <a-modal
        v-model:modalOpen="modalOpen"
        title="Monthly Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeScheduleTimeModal"
    >
      <div class="new-upload-data">
        <div>
          <label>Schedule Time</label>
          <a-time-picker v-model:value="updateScheduleTime" format="HH:mm" />
        </div>
      </div>
    </a-modal>
  </a-row>
</template>

<script>
import {sendRequest} from "@/http/axios.method";
import moment from "moment";

export default {
  name: "ScheduleTime",
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      confirmLoading: false,
      scheduleTime: "00:00",
      modalOpen: false,
      updateScheduleTime: ""
    }
  },
  created() {
    this.getScheduleTime();
  },
  methods: {
    getScheduleTime() {
      sendRequest("get", "admin/schedule-time-get", {}, this.config)
          .then((response) => {
            this.confirmLoading = false;
            if (response.success) {
              this.scheduleTime = response.data.schedule_time
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          });
    },
    closeScheduleTimeModal() {
      if (!this.updateScheduleTime) {
        this.$message.error("Please select schedule time");
        return;
      }
      this.confirmLoading = true;
      sendRequest("post", "admin/schedule-time-set", {schedule_time: moment(this.updateScheduleTime).format('HH:mm')}, this.config)
          .then((response) => {
            this.confirmLoading = false;
            if (response.success) {
              this.updateScheduleTime = "";
              this.modalOpen = false;
              this.getScheduleTime();
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          });
    },
  }
}
</script>

<style scoped>

</style>